import { storyblokEditable, useStoryblokState } from "gatsby-source-storyblok"

import Layout from "../../components/structural/layout"
import PropTypes from "prop-types"
import React from "react"
import Seo from "../../components/seo"
import SmartText from "../../utils/TextHandler"
import { graphql } from "gatsby"
import SectionHero from "../../components/sections/sectionHero"
import SmartTable from "../../utils/TableHandler"
import * as styles from "./legal.module.css"

const Legal = ({ data, pageContext }) => {
  const story = useStoryblokState(data?.page ? data.page : pageContext.story)

  const { content } = story
  const { content: sections, title, label } = content

  return (
    <Layout>
      <div {...storyblokEditable(content)} className="">
        <SectionHero
          blok={{
            title,
          }}
        />
        <div className="py-16 px-4 md:px-8 overflow-scroll">
          {/* {text_content && (
            <section className="px-4">
              <SmartText>{text_content}</SmartText>
            </section>
          )} */}
          <p className="container mx-auto se-body-mobile md:se-body-desktop">
            {label}
          </p>

          {sections &&
            sections.map(section => {
              if (section.component === "copy") {
                return (
                  <section
                    key={section._uid}
                    className="container mx-auto mb-8"
                  >
                    <SmartText containerClassName={styles.legalCopy}>
                      {section.copy}
                    </SmartText>
                  </section>
                )
              } else if (section.component === "heading") {
                return (
                  <section
                    id={section.id}
                    key={section._uid}
                    className="container mx-auto mb-4"
                  >
                    <h2>{section.title}</h2>
                  </section>
                )
              } else if (section.component === "table") {
                return (
                  <section
                    key={section._uid}
                    className="container mx-auto mb-8 overflow-hidden"
                  >
                    <SmartTable
                      containerClassName={styles.legalCopy}
                      data={{ ...section.table }}
                    />
                  </section>
                )
              }
              return null
            })}
        </div>
      </div>
    </Layout>
  )
}

export default Legal

Legal.defaultProps = {
  data: null,
  pageContext: null,
}

Legal.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export const Head = ({ data, pageContext }) => {
  const story = useStoryblokState(data.page || pageContext.story)

  const { seo, seo_author, seo_image, seo_keywords } = story.content

  const siteSettings = JSON.parse(data?.settings?.content)

  return (
    <Seo
      settings={siteSettings}
      {...seo}
      author={seo_author}
      image={seo_image}
      keywords={seo_keywords}
    />
  )
}

export const QUERY = graphql`
  query LegalDefaultQuery {
    settings: storyblokEntry(full_slug: { eq: "site-settings" }) {
      content
      name
      full_slug
      uuid
      id
      internalId
      published_at
    }
  }
`
